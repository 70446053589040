import React from "react";

// Customizable Area Start
import {
  Box,
  BoxProps,
  Grid,
  Typography,
  Table,
  TableCell,
  TableRow,
  Paper,
  TableHead,
  TableBody,
  TableContainer,
  createStyles,
  withStyles,
  CircularProgress,
} from "@material-ui/core";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Bar,
  Area,
  AreaChart,
  Cell,
} from "recharts";
import StarIcon from "@material-ui/icons/Star";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
// Customizable Area End

import LandingPageController, { Props } from "./LandingPageController";

export class LandingPageBlock extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  showList = (taskList: any, index : any) => {
    return (
      <div>
      { taskList.isEmpty ? null :
        <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center", // Centers vertically
          alignItems: "center",     // Ce
        }}
        >
        <Typography display="block" style={{ color: this.taskListFontColor(taskList), fontWeight: 600 }}>
       TL {index + 1}
       </Typography>
       <br />
       <Typography display="block" style={{ color: this.taskListFontColor(taskList), fontSize: "14px" }}>
        {this.taskListSubmitted(taskList)}
       </Typography>
       </Box>
     
}
</div>
    )
  }


  showRating = (rating: number) => {
    return (
    <div style={{ display: "inline-block" }}>
      {[...Array(5).keys()].map((iter) =>
        iter < rating ? (
          <StarIcon
            key={iter}
            style={{ color: "#A046FB" }}
          />
        ) : (
          <StarBorderIcon
            key={iter}
            style={{ color: "gray" }}
          />
        )
      )}
    </div>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const BoxView = Box as React.FC<BoxProps>;

    const BarData = [
      {
        name: this.state.languageSwitch.TotalPatient,
        uv: 4000,
      },
      {
        name: this.state.languageSwitch.AttendedTrials,
        uv: 3000,
      },
      {
        name: this.state.languageSwitch.NotAttended,
        uv: 1000,
      },
    ];
    const Areadatanew= [
      {
        name: "0-5",
        uv: 0.6,
        pv: 2400,
        amt: 2400,
      },
      {
        name: "5-18",
        uv: 0.26,
        pv: 1398,
        amt: 2210,
      },
      {
        name: "18-60",
        uv: 1.5,
        pv: 9800,
        amt: 2290,
      },
      {
        name: "60-60+",
        uv: 0.4,
        pv: 3908,
        amt: 2000,
      },
    ];
   
    const barColors = ["#A046FB", "#361854", "#D93827",""];

    return (
      <>
        <p id="topScroll" style={{position:"absolute",top:"-100",left:"0",width:"100px",height:"100px"}} >Hello</p>
        {this.state.flag === 0 && (
          <BoxView className={this.props.classes.subGrid}>
            <Typography className={this.props.classes.mainPatients}>
              {this.state.languageSwitch.Patients}
            </Typography>
            <Typography className={this.props.classes.subText}>
              {this.state.languageSwitch.patientDescription}
            </Typography>
            <Grid className={this.props.classes.listitem}>
              <BoxView className={this.props.classes.listItemBoxWrap}>
                <BoxView className={this.props.classes.listItemone}>
                  <BoxView className={this.props.classes.listbox}>
                    <Typography id="totalPatients" className={this.props.classes.total}>
                      {this.state.totalPatients}
                    </Typography>
                    <Typography className={this.props.classes.totalPatients}>
                      {this.state.languageSwitch.TotalPatients}
                    </Typography>
                  </BoxView>
                </BoxView>

                <BoxView className={this.props.classes.listItemtwo}>
                  <BoxView className={this.props.classes.listbox}>
                    <Typography className={this.props.classes.total}>
                      300
                    </Typography>
                    <Typography className={this.props.classes.totalPatients}>
                      {this.state.languageSwitch.TrailAttended}
                    </Typography>
                  </BoxView>
                </BoxView>

                <BoxView className={this.props.classes.listItemthree}>
                  <BoxView className={this.props.classes.listbox}>
                    <Typography className={this.props.classes.total}>
                      21
                    </Typography>
                    <Typography className={this.props.classes.totalPatients}>
                      {this.state.languageSwitch.NotAttended}
                    </Typography>
                  </BoxView>
                </BoxView>
              </BoxView>
              <BoxView
                className={this.props.classes.chartBoxWrap}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <BoxView style={{ flexBasis: "45%" }}>
                  <BoxView className={this.props.classes.chartTextWrap}>
                    <Typography className={this.props.classes.chartText}>
                     {this.state.languageSwitch.Patients_Stats}
                    </Typography>
                    <BoxView className={this.props.classes.chartBox}>
                      <ResponsiveContainer width={400} height={400}>
                        <BarChart data={BarData}>
                          <CartesianGrid strokeDasharray="3" />
                          <XAxis
                            dataKey="name"
                            style={{
                              fontFamily: "Jost",
                              fontWeight: "400",
                              fontSize: "13px",
                            }}
                          />
                          <YAxis
                            domain={[0, 6000]}
                            style={{
                              fontFamily: "Jost",
                              fontWeight: "400",
                              fontSize: "14px",
                            }}
                          />
                          <Tooltip />
                          <Bar dataKey="uv" fill="#A046FB" barSize={30}>
                            {BarData.map((entry, index) => (
                              <Cell
                                key={`cell-${index}`}
                                fill={barColors[index % 20]}
                              />
                            ))}
                          </Bar>
                        </BarChart>
                      </ResponsiveContainer>
                    </BoxView>
                  </BoxView>
                </BoxView>
                <BoxView style={{ flexBasis: "45%" }}>
                  <BoxView className={this.props.classes.chartTextWrap}>
                    <Typography className={this.props.classes.chartText}>
                      {this.state.languageSwitch.AttendedPatients}
                    </Typography>
                    <BoxView className={this.props.classes.chartBox}>
                      <ResponsiveContainer width={450} height={400}>
                        <AreaChart
                          width={730}
                          height={250}
                          data={Areadatanew}
                          margin={{ top: 10, right: 30, left: 10, bottom: 0 }}
                        >
                          <defs>
                            <linearGradient
                              id="colorUv"
                              x1="0"
                              y1="0"
                              x2="0"
                              y2="1"
                            >
                              <stop
                                offset="5%"
                                stopColor="#A046FB"
                                stopOpacity={0.8}
                              />
                              <stop
                                offset="95%"
                                stopColor="#A046FB"
                                stopOpacity={0}
                              />
                            </linearGradient>
                            <linearGradient
                              id="colorPv"
                              x1="0"
                              y1="0"
                              x2="0"
                              y2="1"
                            >
                              <stop
                                offset="5%"
                                stopColor="#000000"
                                stopOpacity={0.8}
                              />
                              <stop
                                offset="95%"
                                stopColor="#000000"
                                stopOpacity={0}
                              />
                            </linearGradient>
                          </defs>
                          <XAxis
                            dataKey="name"
                            style={{
                              fontFamily: "Jost",
                              fontWeight: "400",
                              fontSize: "14px",
                            }}
                          />
                          <YAxis
                            label={
                              <text
                                offset="5"
                                style={{
                                  fontFamily: "Inter",
                                  fontWeight: "400",
                                  fontSize: "14px",
                                }}
                                transform="rotate(-90, 25, 234.5)"
                                x="25"
                                y="234.5"
                                className="recharts-text recharts-label"
                                text-anchor="start"
                                fill="#808080"
                              >
                                <tspan x="25" dy="0.355em">
                                 {this.state.languageSwitch.PercentageOfPatients}
                                </tspan>
                              </text>
                            }
                            style={{
                              fontFamily: "Jost",
                              fontWeight: "400",
                              fontSize: "14px",
                            }}
                          />
                          <CartesianGrid strokeDasharray="3 3" />
                          <Tooltip />
                          <Area
                            type="monotone"
                            dataKey="uv"
                            stroke="#A046FB"
                            fillOpacity={1}
                            strokeWidth={2}
                            fill="url(#colorUv)"
                          />
                        </AreaChart>
                      </ResponsiveContainer>
                    </BoxView>
                  </BoxView>
                </BoxView>
              </BoxView>

              <BoxView className={this.props.classes.chartBoxWrap}>
                <BoxView className={this.props.classes.chartTextWrap}>
                  <Typography className={this.props.classes.chartText}>
                    {this.state.languageSwitch.TaskSubmission}
                  </Typography>
                  <BoxView id="taskSubDiv" className={this.props.classes.chartTaskBox}>
                  {this.state.taskSubmissionData.length === 0 ? (
          <div style={{ textAlign: 'center', alignSelf:'center' }}>
            {this.state.languageSwitch.NoDataFound}
          </div>
        ) : (
          <div
          style={{
            width: '100%',
            height: '100%',
            overflowX: 'auto',
            display: 'flex', // Added flex to manage layout
          }}
        >
          <div style={{ minWidth: this.state.taskSubmissionData.length > 20 ? '1000px' :'900px' }}>
                    <ResponsiveContainer width="100%" height="100%">
                      <BarChart
                        data={this.state.taskSubmissionData}
                        margin={{
                          top: 20,
                          right: 30,
                          left: 20,
                          bottom: 5,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                          data-test-id='xaxis'
                          dataKey="name"
                          tickFormatter={(tick) => tick.substring(0, 5)}
                          style={{
                            fontFamily: "Jost",
                            fontWeight: "400",
                            fontSize: "14px",
                          }}
                        />
                        <YAxis
                          label={
                            <text
                              style={{
                                fontFamily: "Inter",
                                fontWeight: "400",
                                fontSize: "14px",
                              }}
                              offset="5"
                              transform="rotate(-90, 25, 234.5)"
                              x="25"
                              y="234.5"
                              className="recharts-text recharts-label"
                              text-anchor="middle"
                              fill="#808080"
                            >
                              <tspan x="25" dy="0.355em">
                               {this.state.languageSwitch.Patients}
                              </tspan>
                            </text>
                          }
                          style={{
                            fontFamily: "Jost",
                            fontWeight: "400",
                            fontSize: "14px",
                          }}
                        />
                        <Tooltip />
                        <Bar
                          dataKey="NotAttended"
                          stackId="a"
                          fill="#EDDCFF"
                          barSize={34}
                        />
                        <Bar
                          dataKey="Attended"
                          stackId="a"
                          fill="#A046FB"
                          barSize={34}
                        />
                      </BarChart>
                    </ResponsiveContainer></div></div>)}{" "}
                  </BoxView>
                </BoxView>
              </BoxView>
              <BoxView className={this.props.classes.chartBoxWrap}>
                <BoxView className={this.props.classes.chartTextWrap}>
                  <Typography className={this.props.classes.chartText}>
                    {this.state.languageSwitch.Milestone}
                  </Typography>
                  <BoxView
                    style={{ position: "relative" }}
                    className={this.props.classes.chartBox}
                  >
                    <Box
                      width={250}
                      position="relative"
                      height={250}
                      style={{ left: "22%", top: "22%" }}
                    >
                      <CircularProgress
                        variant="determinate"
                        size={250}
                        value={100}
                        thickness={4}
                        style={{
                          color: "#E7E7E7",
                          zIndex: "0",
                          position: "absolute",
                        }}
                      />
                      <CircularProgress
                        variant="determinate"
                        size={250}
                        value={(this.state.completedMilestones/this.state.totalMilestones)*100}
                        thickness={4}
                        style={{ zIndex: "1", color: "#A046FB" }}
                      />
                      <Box
                        position="absolute"
                        top="30%"
                        display="flex"
                        left="30%"
                        alignItems="center"
                        flexDirection="column"
                        justifyContent="center"
                        zIndex={3}
                      >
                        <Typography
                          variant="caption"
                          id="completedMilestones"
                          style={{
                            color: "#A046FB",
                            fontFamily: "Jost",
                            fontSize: "40px",
                            fontWeight: "700",
                          }}
                        >
                          {this.state.completedMilestones}
                        </Typography>
                        <Typography
                          variant="caption"
                          style={{
                            color: "#5A5A5A",
                            fontWeight: "400",
                            fontFamily: "Jost",
                            fontSize: "20px",
                          }}
                        >
                          {this.state.languageSwitch.Completed}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      position="absolute"
                      width="100%"
                      bottom="10%"
                      textAlign="center"
                    >
                      <Box
                        width="100%"
                        justifyContent="space-around"
                        display="flex"
                        alignItems="center"
                      >
                        <Box display="flex" alignItems="center">
                          <div
                            style={{
                              width: "12px",
                              height: "12px",
                              backgroundColor: "#E7E7E7",
                              borderRadius: "50%",
                              marginRight: "5px",
                            }}
                          />
                          <Typography
                            variant="caption"
                            id="totalMilestones"
                            style={{
                              color: "#5A5A5A",
                              fontWeight: "400",
                              fontFamily: "Jost",
                              fontSize: "14px",
                            }}
                          >
                            {this.state.languageSwitch.TotalMilestones}: {this.state.totalMilestones}
                          </Typography>
                        </Box>
                        <Box alignItems="center" display="flex">
                          <div
                            style={{
                              width: "12px",
                              borderRadius: "50%",
                              height: "12px",
                              backgroundColor: "#A046FB",
                              marginRight: "5px",
                            }}
                          />
                          <Typography
                            variant="caption"
                            style={{
                              color: "#5A5A5A",
                              fontWeight: "400",
                              fontFamily: "Jost",
                              fontSize: "14px",
                            }}
                          >
                            {this.state.languageSwitch.CompletedMilestones}: {this.state.completedMilestones}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </BoxView>
                </BoxView>
                <BoxView className={this.props.classes.chartTextWrap}>
                  <Typography className={this.props.classes.chartText}>
                    {this.state.languageSwitch.PatientProgress}
                  </Typography>
                  <BoxView className={this.props.classes.chartTaskBox}>
                    {this.state.languageSwitch.CommingSoon}
                  </BoxView>
                </BoxView>
              </BoxView>
              <BoxView className={this.props.classes.chartTextWrap}>
                <Typography className={this.props.classes.chartText}>
                  {this.state.languageSwitch.RecentlyAdded}
                </Typography>
                <TableContainer
                  component={Paper}
                  className={this.props.classes.table}
                >
                 {this.state.patientTableData.length > 0 ? <Table aria-label="customized table">
                    <TableHead>
                      <TableRow className={this.props.classes.tableRow}>
                        <TableCell
                          align="left"
                          className={this.props.classes.tableCell}
                        >
                         {this.state.languageSwitch.SrNo}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={this.props.classes.tableCell}
                        >
                          {this.state.languageSwitch.PatientID}
                        </TableCell>
                        <TableCell align="right" />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.patientTableData.map((rows, indexing) => (
                        <TableRow
                          key={rows.srNo}
                          className={
                            indexing % 2 === 0 ? "" : this.props.classes.tableBody
                          }
                        >
                          <TableCell
                            scope="row"
                            className={this.props.classes.tableCell2}
                          >
                            {rows.srNo}
                          </TableCell>
                          <TableCell
                            align="center"
                            className={this.props.classes.tableCell2}
                          >
                            {rows.patientId}
                          </TableCell>
                          <TableCell
                            align="center"
                            id="reports"
                            className={`${this.props.classes.reports} clickable`}
                            onClick={() => this.handleFlagChange(rows)}
                          >
                            <span style={{ cursor: "pointer" }}>
                              {rows.report}
                            </span>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table> : <Typography id="Nodatafound" className={this.props.classes.Nodatafoundtext}>
                  {this.state.languageSwitch.NoPatientsFound}
                      </Typography>}
                </TableContainer>
              </BoxView>
            </Grid>
          </BoxView>
        )}
        {this.state.flag === 1 && (
          <BoxView className={this.props.classes.subGrid}>
           <div style={{ display: 'flex', justifyContent: 'space-between',alignItems:"center", width:'75%' }}>
        <Typography className={this.props.classes.mainPatients}>
          {this.state.patientName}'s Reports
        </Typography>
        <div onClick={this.handleNewFlagChange} style={{background:"white"}}>
          <button style={{width:"85px",border:"2px solid #A046FB", borderRadius:"10px", alignItems:"center", display:"flex",padding:"8px", justifyContent:"space-between", cursor:"pointer"}}>
          <KeyboardBackspaceIcon style={{color:"#A046FB", cursor:"pointer"}} />
          <Typography className={this.props.classes.subText} style={{fontWeight:"bold"}}>
          {this.state.languageSwitch.Back}
            </Typography>
          </button>
        </div>
      </div>
            <Typography className={this.props.classes.subText}>
              {this.state.languageSwitch.patientReportPageDescription}
            </Typography>
            <Grid className={this.props.classes.listitem}>
              <BoxView className={this.props.classes.listItemBoxWrap}>
                <BoxView className={this.props.classes.listItemone}>
                  <BoxView className={this.props.classes.listbox}>
                    <Typography className={this.props.classes.total} id="patientIdName">
                      {this.state.patientID}
                    </Typography>
                    <Typography className={this.props.classes.totalPatients}>
                     {this.state.languageSwitch.PatientID}
                    </Typography>
                  </BoxView>
                </BoxView>

                <BoxView className={this.props.classes.listItemtwo}>
                  <BoxView className={this.props.classes.listbox}>
                    <Typography className={this.props.classes.total}>
                      {this.state.singlePatientData.site_id}
                    </Typography>
                    <Typography className={this.props.classes.totalPatients}>
                      {this.state.languageSwitch.Site_ID}
                    </Typography>
                  </BoxView>
                </BoxView>

                <BoxView className={this.props.classes.listItemthree}>
                  <BoxView className={this.props.classes.listbox}>
                    <Typography id="siteCoordinatorName" className={this.props.classes.total}>
                      {this.state.singlePatientData.site_coordinator_account_full_name}
                    </Typography>
                    <Typography className={this.props.classes.totalPatients}>
                      {this.state.languageSwitch.Site_Coordinator}
                    </Typography>
                  </BoxView>
                </BoxView>
              </BoxView>
              <BoxView
                className={this.props.classes.chartBoxWrap}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <BoxView style={{ flexBasis: "45%" }}>
                  <BoxView className={this.props.classes.chartTextWrap}>
                    <Typography className={this.props.classes.chartText}>
                      {this.state.languageSwitch.PointsEarned}
                    </Typography>
                    <BoxView
                      style={{ position: "relative" }}
                      className={this.props.classes.chartBox}
                    >
                      <Box
                        position="relative"
                        height={250}
                        width={250}
                        style={{ top: "22%", left: "22%" }}
                      >
                        <CircularProgress
                          variant="determinate"
                          value={100}
                          thickness={4}
                          size={250}
                          style={{
                            zIndex: "0",
                            color: "#E7E7E7",
                            position: "absolute",
                          }}
                        />
                        <CircularProgress
                          variant="determinate"
                          thickness={4}
                          value={(this.state.rewardsPoints.points_earned.earned_points/this.state.rewardsPoints.points_earned.total_point)*100}
                          size={250}
                          style={{ zIndex: "1", color: "#A046FB" }}
                        />
                        <Box
                          position="absolute"
                          left="35%"
                          justifyContent="center"
                          top="30%"
                          display="flex"
                          flexDirection="column"
                          alignItems="center"
                          zIndex={3}
                        >
                          <Typography
                            variant="caption"
                            style={{
                              color: "#A046FB",
                              fontWeight: "700",
                              fontFamily: "Jost",
                              fontSize: "40px",
                            }}
                          >
                            {this.state.rewardsPoints.points_earned.earned_points}
                          </Typography>
                          <Typography
                            variant="caption"
                            style={{
                              color: "#5A5A5A",
                              fontWeight: "400",
                              fontFamily: "Jost",
                              fontSize: "20px",
                            }}
                          >
                            {this.state.languageSwitch.Earned}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        position="absolute"
                        width="100%"
                        textAlign="center"
                        bottom="10%"
                      >
                        <Box
                          width="100%"
                          display="flex"
                          alignItems="center"
                          justifyContent="space-around"
                        >
                          <Box alignItems="center" display="flex">
                            <div
                              style={{
                                width: "12px",
                                borderRadius: "50%",
                                height: "12px",
                                backgroundColor: "#E7E7E7",
                                marginRight: "5px",
                              }}
                            />
                            <Typography
                              variant="caption"
                              style={{
                                color: "#5A5A5A",
                                fontWeight: "400",
                                fontFamily: "Jost",
                                fontSize: "14px",
                              }}
                            >
                              {this.state.languageSwitch.TotalPoints}: {this.state.rewardsPoints.points_earned.total_point}
                            </Typography>
                          </Box>
                          <Box alignItems="center" display="flex">
                            <div
                              style={{
                                width: "12px",
                                height: "12px",
                                borderRadius: "50%",
                                marginRight: "5px",
                                backgroundColor: "#A046FB",
                              }}
                            />
                            <Typography
                              variant="caption"
                              style={{
                                color: "#5A5A5A",
                                fontWeight: "400",
                                fontFamily: "Jost",
                                fontSize: "14px",
                              }}
                            >
                              {this.state.languageSwitch.EarnedPoints}: {this.state.rewardsPoints.points_earned.earned_points}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </BoxView>
                  </BoxView>
                </BoxView>
                <BoxView style={{ flexBasis: "45%" }}>
                  <BoxView className={this.props.classes.chartTextWrap}>
                    <Typography className={this.props.classes.chartText}>
                     {this.state.languageSwitch.RewardsEarned}
                    </Typography>
                    <BoxView
                      style={{ position: "relative" }}
                      className={this.props.classes.chartBox}
                    >
                      <Box
                        position="relative"
                        width={250}
                        height={250}
                        style={{ left: "22%", top: "22%" }}
                      >
                        <CircularProgress
                          variant="determinate"
                          value={100}
                          size={250}
                          thickness={4}
                          style={{
                            color: "#E7E7E7",
                            position: "absolute",
                            zIndex: "0",
                          }}
                        />
                        <CircularProgress
                          variant="determinate"
                          thickness={4}
                          value={this.rewardGraphValue()}
                          size={250}
                          style={{ zIndex: "1", color: "#A046FB" }}
                        />
                        <Box
                          position="absolute"
                          left="31%"
                          top="28%"
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          alignItems="center"
                          zIndex={3}
                        >
                          <Typography
                            variant="caption"
                            style={{
                              color: "#A046FB",
                              fontFamily: "Jost",
                              fontSize: "40px",
                              fontWeight: "700",
                            }}
                          >
                            {this.state.rewardsPoints.rewards_points.total_redeem}
                          </Typography>
                          <Typography
                            variant="caption"
                            style={{
                              color: "#5A5A5A",
                              fontWeight: "400",
                              fontFamily: "Jost",
                              fontSize: "20px",
                            }}
                          >
                            {this.state.languageSwitch.Redeemed}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        position="absolute"
                        bottom="10%"
                        textAlign="center"
                        width="100%"
                      >
                        <Box
                          width="100%"
                          display="flex"
                          alignItems="center"
                          justifyContent="space-around"
                        >
                          <Box display="flex" alignItems="center">
                            <div
                              style={{
                                width: "12px",
                                height: "12px",
                                backgroundColor: "#E7E7E7",
                                borderRadius: "50%",
                                marginRight: "5px",
                              }}
                            />
                            <Typography
                              variant="caption"
                              style={{
                                color: "#5A5A5A",
                                fontWeight: "400",
                                fontFamily: "Jost",
                                fontSize: "14px",
                              }}
                            >
                              {this.state.languageSwitch.TotalEarned}: {this.state.rewardsPoints.rewards_points.total_earn}
                            </Typography>
                          </Box>
                          <Box display="flex" alignItems="center">
                            <div
                              style={{
                                width: "12px",
                                height: "12px",
                                borderRadius: "50%",
                                backgroundColor: "#A046FB",
                                marginRight: "5px",
                              }}
                            />
                            <Typography
                              variant="caption"
                              style={{
                                color: "#5A5A5A",
                                fontFamily: "Jost",
                                fontWeight: "400",
                                fontSize: "14px",
                              }}
                            >
                              {this.state.languageSwitch.TotalRedeemed}: {this.state.rewardsPoints.rewards_points.total_redeem}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </BoxView>
                  </BoxView>
                </BoxView>
              </BoxView>

              <BoxView className={this.props.classes.chartBoxWrap}>
                <BoxView className={this.props.classes.chartTextWrap}>
                  <Typography className={this.props.classes.chartText}>
                   {this.state.languageSwitch.TaskList}
                  </Typography>
                  <BoxView
                    className={this.props.classes.chartBox}
                    style={{ position: "relative" }}
                  >
                    <Box
                      position="relative"
                      height={250}
                      width={250}
                      style={{ left: "22%", top: "22%" }}
                    >
                      <CircularProgress
                        variant="determinate"
                        size={250}
                        value={100}
                        thickness={4}
                        style={{
                          color: "#E7E7E7",
                          zIndex: "0",
                          position: "absolute",
                        }}
                      />
                      <CircularProgress
                        variant="determinate"
                        size={250}
                        value={this.taskListAttended()}
                        thickness={4}
                        style={{ color: "#A046FB", zIndex: "1" }}
                      />
                      <Box
                        position="absolute"
                        left="35%"
                        top="30%"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                        zIndex={3}
                      >
                        <Typography
                          variant="caption"
                          style={{
                            color: "#A046FB",
                            fontWeight: "700",
                            fontFamily: "Jost",
                            fontSize: "40px",
                          }}
                        >
                          {this.state.singlePatientData.task_list.task_attended}
                        </Typography>
                        <Typography
                          variant="caption"
                          style={{
                            color: "#5A5A5A",
                            fontFamily: "Jost",
                            fontWeight: "400",
                            fontSize: "20px",
                          }}
                        >
                          {this.state.languageSwitch.Attended}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      position="absolute"
                      width="100%"
                      bottom="10%"
                      textAlign="center"
                    >
                      <Box
                        width="100%"
                        justifyContent="space-around"
                        display="flex"
                        alignItems="center"
                      >
                        <Box alignItems="center" display="flex">
                          <div
                            style={{
                              width: "12px",
                              height: "12px",
                              backgroundColor: "#E7E7E7",
                              borderRadius: "50%",
                              marginRight: "5px",
                            }}
                          />
                          <Typography
                            variant="caption"
                            style={{
                              color: "#5A5A5A",
                              fontWeight: "400",
                              fontFamily: "Jost",
                              fontSize: "14px",
                            }}
                          >
                           {this.state.languageSwitch.TaskAssigned}: {this.state.singlePatientData.task_list.task_assigned_point}
                          </Typography>
                        </Box>
                        <Box display="flex" alignItems="center">
                          <div
                            style={{
                              width: "12px",
                              height: "12px",
                              borderRadius: "50%",
                              backgroundColor: "#A046FB",
                              marginRight: "5px",
                            }}
                          />
                          <Typography
                            variant="caption"
                            style={{
                              color: "#5A5A5A",
                              fontFamily: "Jost",
                              fontWeight: "400",
                              fontSize: "14px",
                            }}
                          >
                            {this.state.languageSwitch.TaskAttended}: {this.state.singlePatientData.task_list.task_attended}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </BoxView>
                </BoxView>
                <BoxView className={this.props.classes.chartTextWrap}>
                  <Typography className={this.props.classes.chartText}>
                  {this.state.languageSwitch.TaskListInformation}
                  </Typography>
                  <BoxView className={this.props.classes.chartBox} style={{ overflowY : "scroll"}} sx={{  border: '1px solid #ccc', borderRadius: 6 }}>
                    {
                      this.state.taskListInformation.length == 0 ? 
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "inherit"
                          }}  
                        >
                          <Typography>{this.state.languageSwitch.NoDataFound}</Typography>
                        </Box>
                      :
                 
                    <Grid container >
                      { this.state.taskListInformation.map((taskList: any, index : number) => (
                        <Grid item xs={12} md={3} key={index}>
                          <Box
                            sx={{
                              height: '85px',
                              bgcolor: this.taskListBgColor(taskList),
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center", // Centers vertically
                              alignItems: "center",     // Ce
                              borderRadius: 1,
                              border: '1px solid #dddddd', // Add a white border
                            }}
                            style={{
                              borderCollapse: "collapse"
                            }}
                          > 
                          {this.showList(taskList, index)}
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                      }
                  </BoxView>
                  
                </BoxView>
              </BoxView>
              <BoxView className={this.props.classes.chartBoxWrap}>
                <BoxView className={this.props.classes.chartTextWrap}>
                  <Typography className={this.props.classes.chartText}>
                  {this.state.languageSwitch.Milestone}
                  </Typography>
                  <BoxView
                    style={{ position: "relative" }}
                    className={this.props.classes.chartBox}
                  >
                    <Box
                      position="relative"
                      height={250}
                      width={250}
                      style={{ left: "22%", top: "22%" }}
                    >
                      <CircularProgress
                        variant="determinate"
                        size={250}
                        value={100}
                        thickness={4}
                        style={{
                          color: "#E7E7E7",
                          zIndex: "0",
                          position: "absolute",
                        }}
                      />
                      <CircularProgress
                        variant="determinate"
                        value={(this.state.completedMilestones/this.state.totalMilestones)*100}
                        thickness={4}
                        size={250}
                        style={{ color: "#A046FB", zIndex: "1" }}
                      />
                      <Box
                        position="absolute"
                        top="30%"
                        left="30%"
                        alignItems="center"
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        zIndex={3}
                      >
                        <Typography
                          variant="caption"
                          style={{
                            color: "#A046FB",
                            fontWeight: "700",
                            fontFamily: "Jost",
                            fontSize: "40px",
                          }}
                        >
                          {this.state.singlePatientData.milestone_managements.completed_milestones}
                        </Typography>
                        <Typography
                          variant="caption"
                          style={{
                            color: "#5A5A5A",
                            fontWeight: "400",
                            fontFamily: "Jost",
                            fontSize: "20px",
                          }}
                        >
                           {this.state.languageSwitch.Completed}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      position="absolute"
                      width="100%"
                      bottom="10%"
                      textAlign="center"
                    >
                      <Box
                        width="100%"
                        display="flex"
                        alignItems="center"
                        justifyContent="space-around"
                      >
                        <Box display="flex" alignItems="center">
                          <div
                            style={{
                              width: "12px",
                              height: "12px",
                              backgroundColor: "#E7E7E7",
                              borderRadius: "50%",
                              marginRight: "5px",
                            }}
                          />
                          <Typography
                            variant="caption"
                            style={{
                              color: "#5A5A5A",
                              fontFamily: "Jost",
                              fontSize: "14px",
                              fontWeight: "400",
                            }}
                          >
                            {this.state.languageSwitch.TotalMilestones}: {this.state.singlePatientData.milestone_managements.total_milestones}
                          </Typography>
                        </Box>
                        <Box display="flex" alignItems="center">
                          <div
                            style={{
                              width: "12px",
                              height: "12px",
                              backgroundColor: "#A046FB",
                              borderRadius: "50%",
                              marginRight: "5px",
                            }}
                          />
                          <Typography
                            variant="caption"
                            style={{
                              color: "#5A5A5A",
                              fontFamily: "Jost",
                              fontSize: "14px",
                              fontWeight: "400",
                            }}
                          >
                            {this.state.languageSwitch.CompletedMilestones}: {this.state.singlePatientData.milestone_managements.completed_milestones}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </BoxView>
                </BoxView>
                <BoxView className={this.props.classes.chartTextWrap}>
                  <Typography className={this.props.classes.chartText}>
                    {this.state.languageSwitch.VisitSchedule}
                  </Typography>
                  <BoxView className={this.props.classes.chartBox}>
                    <ResponsiveContainer width={400} height={400} style={{marginTop:"10%"}}>
                      <BarChart data={this.state.visitScheduleData}>
                      <CartesianGrid vertical={false} stroke="#8884d8" strokeDasharray="3" />
                        <XAxis
                          dataKey="name"
                          style={{
                            fontFamily: "Jost",
                            fontWeight: "400",
                            fontSize: "14px",
                          }}
                        />
                        <YAxis
                         axisLine={false}
                          domain={[0, 500]}
                          style={{
                            fontFamily: "Jost",
                            fontWeight: "400",
                            fontSize: "14px",
                          }}
                        />
                        <Tooltip />
                        <Bar
                          id="bardata"
                          dataKey="uv"
                          fill="#A046FB"
                          barSize={30}
                        >
                          {this.state.visitScheduleData.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill="#A046FB" />
                          ))}
                        </Bar>
                      </BarChart>
                    </ResponsiveContainer>
                  </BoxView>
                </BoxView>
              </BoxView>
              <BoxView className={this.props.classes.chartTextWrap}>
                <Typography className={this.props.classes.chartText}>
                {this.state.languageSwitch.Feedback}
                </Typography>
                <TableContainer
                  component={Paper}
                  className={this.props.classes.table}
                >
                  { this.state.taskFeedbackPatient.length > 0 ?
                  <Table aria-label="customized table">
                    <TableHead>
                      <TableRow className={this.props.classes.tableRow}>
                        <TableCell
                          align="left"
                          className={this.props.classes.tableCell}
                        >
                           {this.state.languageSwitch.SrNo}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={this.props.classes.tableCell}
                        >
                          {this.state.languageSwitch.DateTime}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={this.props.classes.tableCell}
                        >
                           {this.state.languageSwitch.Rating}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={this.props.classes.tableCell}
                        >
                           {this.state.languageSwitch.Remarks}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.taskFeedbackPatient.map((item, index) => (
                        <TableRow
                          id="tableRowTest"
                          key={index}
                          className={
                            index % 2 === 0 ? "" : this.props.classes.tableBody
                          }
                        >
                          <TableCell
                            scope="row"
                            className={this.props.classes.tableCell2}
                          >
                            {index + 1}
                          </TableCell>
                          <TableCell
                            align="center"
                            className={this.props.classes.tableCell2}
                          >
                           { this.formatDate( new Date(item.attributes.created_at), 'en-US')}
                          </TableCell>
                          <TableCell
                            align="center"
                            className={this.props.classes.tableCell2}
                          >
                            {this.showRating(item.attributes.rating)}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ "maxWidth" : '100px' }}
                            
                            className={this.props.classes.tableCell2}
                          >
                            <Typography variant="body2" style={{ maxWidth: '250px' }} >
                            {this.reviewView(item.attributes.comments, index)}
                            {   <span 
                                  id="reviewExpand"
                                  onClick={() => this.expandIndexFun(index)} 
                                  style={{ cursor: 'pointer', color: '#A046FB' }}
                                >
                            {this.reviewExpand(index, item.attributes.comments)}
                            </span>}
                          </Typography>
                         
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>

                  : <Typography id="Nodatafound" className={this.props.classes.Nodatafoundtext}>
                    {this.state.languageSwitch.StudyNotFound}
                      </Typography>}

                </TableContainer>
              </BoxView>
            </Grid>
          </BoxView>
        )}
      </>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
const styles = () =>
  createStyles({
    studySelectGrp: {
      color: "#000",
      fontfamily: "Inter Tight",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 500,
      cursor: "pointer",
    },
    mainheadertop: {
      padding: 20,
      position: "sticky",
      top: 0,
      left: 0,
      right: 0,
      background: "#FFF",
      borderBottom: "1px solid rgba(161, 161, 161, 0.50)",
      zIndex: 1,
    },
    mainlogo: {
      width: 150,
    },
    headerAvtar: {
      verticalAlign: "middle",
      marginRight: "12px",
    },
    "& .dropdownLogo": {
      verticalAlign: "middle",
      cursor: "pointer",
    },
    userName: {
      color: "#000",
      fontfamily: "Jost",
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 500,
    },
    topBox: {
      display: "flex",
      alignItems: "center",
      gap: "20px",
    },
    PatientsText: {
      color: "#A046FB",
      textAlign: "center" as const,
      fontWeight: 600,
      marginLeft: "10px",
    },
    Patients: {
      paddingTop: "40px",
    },
    subPatients: {
      display: "flex",
      justifyContent: "center",
      padding: "22px",
      backgroundColor: "#fff",
      textAlign: "center" as const,
      borderRadius: "0px 20px 20px 0px",
      marginRight: "25px",
    },
    mainPatients: {
      color: "#1F3650",
      fontFamily: "Jost",
      fontSize: "40px",
      fontWeight: 700,
    },
    subGrid: {
      margin: "40px",
    },
    subText: {
      color: "#414141",
      fontFamily: "Jost",
      fontSize: "14px",
      width : "80%"
    },
    listItemone: {
      backgroundColor: "#A046FB",
      marginTop: "30px",
      width: "286px",
      height: "145px",
      borderRadius: "8px",
      color: "#fff",
    },
    listbox: {
      padding: "40px 25px",
    },
    total: {
      fontFamily: "Jost",
      fontSize: "32px",
      fontWeight: 700,
    },
    totalPatients: {
      fontFamily: "Jost",
      fontSize: "18px",
    },
    listitem: {
      display: "flex",
      flexDirection: "column",
      gap: "40px",
    },
    listItemBoxWrap: {
      display: "flex",
    },
    chartBoxWrap: {
      display: "flex",
      gap: "26px",
      maxWidth: "900px",
    },
    chartBox: {
      height: "484px",
      width: "100%",
      borderRadius: "8px",
      border: "1px solid #EFEFEF",
    },
    chartTaskBox: {
      height:"484px",
      width: '100%',
      borderRadius: "8px",
      border: "1px solid #EFEFEF",
      display: "flex",
      justifyContent: "center", // Centers vertically
      alignItems: "center",  
    },
    chartTextWrap: {
      width: "100%",
    },
    chartText: {
      color: "#292929",
      fontFamily: "Jost",
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 600,
      marginBottom: "20px",
    },
    listItemtwo: {
      backgroundColor: "#F0F0F0",
      marginTop: "30px",
      marginLeft: "25px",
      width: "286px",
      height: "145px",
      borderRadius: "8px",
      color: "#1F3650",
    },
    listItemthree: {
      backgroundColor: "#F0F0F0",
      marginTop: "30px",
      marginLeft: "25px",
      minWidth: "286px",
      height: "145px",
      borderRadius: "8px",
      color: "#1F3650",
    },
    reports: {
      color: "#A046FB",
      fontFamily: "Jost",
      fontSize: "14px",
      fontWeight: 500,
      border: "none",
    },
    table: {
      maxWidth: "900px",
      boxShadow: "none",
    },
    tableRow: {
      borderRadius: "8px",
      background: "#EFEFEF",
    },
    tableCell: {
      color: "#182221",
      fontFamily: "Jost",
      fontSize: "14px",
      fontWeight: 700,
    },
    tableCell2: {
      border: "none",
      color: "#202423",
      fontFamily: "Jost",
      fontSize: "14px",
      height: "75px",
    },
    tableBody: {
      backgroundColor: "#F0F0F0",
    },
    Nodatafoundtext :{
      color: "#292929",
      fontFamily: "Jost",
      fontSize: "15px",
      fontStyle: "normal",
      fontWeight: 600,
      marginBottom: "20px",
      margin : "12px"
    }
  });
export default withStyles(styles)(LandingPageBlock);
// Customizable Area End
